export default {
  YOU_MAY_ALSO_LIKE: 'Related Products',

  VEHICLE_CATEGORY_TITLE_CLASS: 'h2',

  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size count-bubble" key="garage-size">{{size}}</span>',

  FITMENT_UNIVERSAL_TITLE: 'MULTI-FIT',
  FITMENT_UNIVERSAL_TEXT:
    'Fitment is not solely based on Year/Make/Model. It has a general fitment across a variety of models/parts/accessories.',
};
