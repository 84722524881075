
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function onClick1(e) {
        window.Convermax.relatedItemsSliderBtnHandler(e);
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'product-card cm_product-item grid-item__link' }, _createElement('div', { 'className': 'product-card__figure' }, _createElement('a', {
                    'href': this.url,
                    'className': 'cm_product-item__image-container'
                }, _createElement('img', {
                    'className': 'cm_product-item__image' + (this.image2 ? ' cm_product-item__image_primary' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'cm_product-item__image cm_product-item__image_secondary',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '411'
                }) : null), _createElement('div', { 'className': 'product-card__badge-container' }, this.badges?.includes('New') ? _createElement('div', {
                    'className': 'product-card__badge badge-new',
                    'key': '700'
                }, 'New') : null, this.badges?.includes('Featured') ? _createElement('div', {
                    'className': 'product-card__badge badge-featured',
                    'key': '806'
                }, 'Featured') : null, this.badges?.includes('Sale') ? _createElement('div', {
                    'className': 'product-card__badge badge-sale',
                    'key': '927'
                }, 'Sale') : null), _createElement('div', { 'className': 'product-card__quick-buy' }, _createElement('div', { 'className': 'pointer-fine:hidden' }, _createElement('button', {
                    'type': 'button',
                    'aria-controls': 'quick-buy-drawer ' + this.id,
                    'aria-expanded': 'false',
                    'aria-label': '+ Quick add',
                    'is': 'custom-button',
                    'className': 'product-card__mobile-quick-buy-button'
                }, _createElement('div', {}, _createElement('svg', {
                    'role': 'presentation',
                    'fill': 'none',
                    'strokeWidth': '2',
                    'focusable': 'false',
                    'width': '16',
                    'height': '14',
                    'className': 'icon icon-quick-buy-cart',
                    'viewBox': '0 0 16 14'
                }, _createElement('path', {
                    'd': 'M7.75 4.75H2.283a1 1 0 0 0-.97 1.244l1.574 6.25a1 1 0 0 0 .97.756h7.787a1 1 0 0 0 .97-.756l1.573-6.25a1 1 0 0 0-.97-1.244H7.75Zm0 0V1',
                    'stroke': 'currentColor',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))), _createElement('span', { 'className': 'button__loader' }, _createElement('span', {}), _createElement('span', {}), _createElement('span', {})))), _createElement('div', { 'className': 'pointer-coarse:hidden' }, _createElement('button', {
                    'type': 'button',
                    'className': 'button',
                    'aria-controls': 'quick-buy-drawer ' + this.id,
                    'aria-expanded': 'false',
                    'is': 'custom-button'
                }, _createElement('div', {}, '+ Quick add'), _createElement('span', { 'className': 'button__loader' }, _createElement('span', {}), _createElement('span', {}), _createElement('span', {})))), _createElement('quick-buy-drawer', {
                    'id': 'quick-buy-drawer ' + this.id,
                    'header-bordered': true,
                    'open-from': 'bottom',
                    'handle': this.handle,
                    'role': 'dialog',
                    'aria-live': 'polite',
                    'aria-modal': 'true',
                    'class': 'quick-buy-drawer drawer'
                }))), _createElement('div', { 'className': 'cm_product-item__details' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'cm_product-item__title'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'cm_review-stars-container' }, this.review_count ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_review-stars',
                        'key': '2685'
                    },
                    _createElement('span', { 'className': 'cm_review-average' }, Math.fround(this.review_average).toFixed(1)),
                    _map([
                        0,
                        1,
                        2,
                        3,
                        4
                    ], repeatI2.bind(this)),
                    _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ') ', _createElement('span', { 'className': 'cm_subtitle' }, 'reviews'))
                ]) : null), _createElement('div', { 'className': 'cm_product-item__price-container' }, this.on_sale ? _createElement('div', {
                    'className': 'cm_product-item__price cm_product-item__price_compare',
                    'key': '3284'
                }, '\n        ', this.formatPrice(this.compare_at_price), '\n      ') : null, _createElement('div', { 'className': 'cm_product-item__price' }, this.price_varies ? _createElement('span', { 'key': '3487' }, 'From: ') : null, '\n        ', this.formatPrice(this.price), '\n      '))), this.sku ? _createElement('div', {
                    'className': 'cm_product-item__sku',
                    'key': '3607'
                }, '\n    Part Number: ', _createElement('span', mergeProps({ 'className': 'sku' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null);
            }, { count: undefined })];
    }
    function onClick3(e) {
        window.Convermax.relatedItemsSliderBtnHandler(e);
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'Related Products'), _createElement('a', {
        'className': 'cm_related-products-link',
        'href': '/collections/all'
    }, 'View all')), _createElement('div', {
        'id': 'press_bg',
        'className': 'cm_related-products-slider'
    }, _createElement('button', {
        'className': 'circle-button ring group cm_related-products-slider-button prev',
        'onClick': onClick1.bind(this)
    }, _createElement('span', { 'className': 'sr-only' }, 'Previous'), _createElement('span', { 'className': 'animated-arrow animated-arrow--reverse' })), _createElement('div', { 'className': 'cm_related-products-list-container' }, _createElement.apply(this, [
        'div',
        {
            'className': 'cm_related-products-list cmRepeater_items',
            'data-position': '0'
        },
        _map(this.items, repeatItems2.bind(this))
    ])), _createElement('button', {
        'className': 'circle-button ring group cm_related-products-slider-button next',
        'onClick': onClick3.bind(this)
    }, _createElement('span', { 'className': 'sr-only' }, 'Next'), _createElement('span', { 'className': 'animated-arrow' }))));
}
        export const componentNames = []