import shopifyGenericDefaults from '../shopify-generic/config.js';

window.Convermax.relatedItemsSliderBtnHandler = (e) => {
  const sliderEl = document.querySelector('.cm_related-products .cm_related-products-slider');
  const productsContainerEl = sliderEl.querySelector('.cm_related-products-list');
  const productItemWidth = +sliderEl.querySelector('.product-card').clientWidth;
  const productsAmount = +sliderEl.querySelectorAll('.product-card').length;

  const isPrev = e.target.classList.contains('prev');
  const position = +productsContainerEl.getAttribute('data-position') + (isPrev ? -1 : 1);

  productsContainerEl.setAttribute('data-position', position);

  if (isPrev) {
    if (position <= 0) {
      e.target.setAttribute('disabled', 'true');
    }

    sliderEl.querySelector('.next').removeAttribute('disabled');
  } else {
    if (position >= productsAmount - Math.round(productsContainerEl.clientWidth / productItemWidth)) {
      e.target.setAttribute('disabled', 'true');
    }

    sliderEl.querySelector('.prev').removeAttribute('disabled');
  }

  productsContainerEl.style.transform = `translateX(-${position * productItemWidth}px)`;
};

const initRelatedItemsSlider = () => {
  const sliderEl = document.querySelector('.cm_related-products .cm_related-products-slider');
  if (!sliderEl) {
    return;
  }

  const productsContainerEl = sliderEl.querySelector('.cm_related-products-list');
  const productItemWidth = sliderEl.querySelector('.product-card').clientWidth;
  const productsAmount = sliderEl.querySelectorAll('.product-card').length;
  const position = +productsContainerEl.getAttribute('data-position');

  if (productsContainerEl.clientWidth >= productItemWidth * productsAmount - productsAmount) {
    // if all products can show in slider container
    sliderEl
      .querySelectorAll('.cm_related-products-slider-button')
      .forEach((el) => (el.style.display = 'none'));
    productsContainerEl.style.transform = `translateX(0px)`;
  } else {
    // if products more then we can show in slider container
    sliderEl
      .querySelectorAll('.cm_related-products-slider-button')
      .forEach((el) => (el.style.display = 'block'));

    if (position === 0) {
      sliderEl.querySelector('.prev').setAttribute('disabled', 'true');
      productsContainerEl.style.transform = `translateX(0px)`;
    } else {
      const maxPos = productsAmount - Math.round(productsContainerEl.clientWidth / productItemWidth);
      if (position >= maxPos) {
        // if position bigger, we set grid on max view position and disable next button
        productsContainerEl.setAttribute('data-position', maxPos);
        sliderEl.querySelector('.next').setAttribute('disabled', 'true');
        productsContainerEl.style.transform = `translateX(-${maxPos * productItemWidth}px)`;
      } else {
        // recalcuate position for view
        sliderEl.querySelector('.next').removeAttribute('disabled');
        productsContainerEl.style.transform = `translateX(-${position * productItemWidth}px)`;
      }
    }
  }
};

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();

  if (window.ShopifyAnalytics?.meta.page.pageType === 'product') {
    window.addEventListener('resize', initRelatedItemsSlider);
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  pagination: {
    centralRange: 0,
    edgeRange: 1,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchHeader', 'SearchBox', 'RelatedItems'].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        '_popularity:desc': 'Best Selling',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: () => window.document.querySelector('#search-drawer').removeAttribute('open'),
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: 10,
      onItemsRendered: initRelatedItemsSlider,
      useManualItems: true,
    },
  ],
};
